export default (app) => {
  app.mixin({
    methods: {
      $removeQueryUrl(queryValue) {
        let query = Object.assign({}, this.$route.query);
        if (query[queryValue]) {
          delete query[queryValue];
          this.$router.replace({ query });
        } 
      }
    },
  });
};
      