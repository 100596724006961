import * as Validator from "validatorjs";
import validatorMessages from "@/core/validator-messages";

/**
 * Percorre as mensagens customizadas e seta no validador antes
 * de inciiar a validação
 */
Object.keys(validatorMessages).forEach((lang) => {
  let messages = Validator.getMessages(lang);
  Validator.setMessages(lang, { ...messages, ...validatorMessages[lang] });
});

export default (app) => {
  app.mixin({
    methods: {
      /**
       * Valida o formulário passado com base nas regras,
       * retorna os erros encontrados
       */
      $validator(formData, rules, customMessage) {
        const { $i18n } = this;
        Validator.useLang($i18n.locale);

        const validator = new Validator(formData, rules, customMessage || {});
        validator.passes();
        return validator.errors.all();
      },
    },
  });
};
