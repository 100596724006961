import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'auth-login',
    meta: { requireLogin: false },
    component: () => import(/* webpackChunkName: "auth-login" */ '@/pages/login')
  },

  {
    path: '/auth/signup',
    name: 'auth-signup',
    meta: { requireLogin: false },
    component: () => import(/* webpackChunkName: "auth-signup" */ '@/pages/signup')
  },

  {
    path: '/auth/forgot-password',
    name: 'auth-forgot-password',
    meta: { requireLogin: false },
    component: () => import(/* webpackChunkName: "auth-forgot-password" */ '@/pages/forgot-password')
  },

  {
    path: '/auth/recovery/:token',
    name: 'auth-recovery',
    meta: { requireLogin: false },
    component: () => import(/* webpackChunkName: "auth-recovery" */ '@/pages/recovery')
  },

  {
    path: '/profile',
    name: 'profile',
    meta: { requireLogin: true },
    component: () => import(/* webpackChunkName: "profile" */ '@/pages/profile')
  },

  {
    path: '/products',
    name: 'products',
    meta: { requireLogin: true },
    component: () => import(/* webpackChunkName: "products" */ '@/pages/products')
  },

  {
    path: "/:pathMatch(.*)*",
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '@/pages/404')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router