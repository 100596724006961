import { createStore } from 'vuex'

export default createStore({
  state: {
    user: {},
    token: '',
    loading: false,
  },
  getters: {
    getUser: (state) => state.user,
    getToken: (state) => state.token,
    getLoading: (state) => state.loading,
  },
  mutations: {
    SET_USER: (state, value) => (state.user = value),
    SET_TOKEN: (state, value) => {
      state.token = value;
      if (value === "") {
        localStorage.removeItem("token");
      } else {
        localStorage.setItem("token", value);
      }
    },
    SET_LOADING: (state, value) => (state.loading = value),
  },
  actions: {
    /**
     * Ao iniciar o módulo temos que mostrar o loader global enquanto
     * autenticamos o token armazenado, caso o token for inválido ele
     * é apagado
     */
     async auth({ commit }) {
      try {
        const token = localStorage.getItem("token");
        if (token) {
          commit("SET_LOADING", true);
          const response = await window.$request().get("/auth");
          const { user, token } = response.data;

          commit("SET_USER", user);
          commit("SET_TOKEN", token);
        }
      } catch (error) {
        console.log("[startModule]", error);
        commit("SET_TOKEN", "");
      } finally {
        commit("SET_LOADING", false);
      }
    },

    /**
     * Chama a rota de login de um usuário, quando criado seta o token
     * e retorna o evento done
     */
     async login({ commit }, { data, done, fail }) {
      try {
        const response = await window.$request().post("/auth/login", data);
        const { user, token } = response.data;
        commit("SET_USER", user);
        commit("SET_TOKEN", token);
        done(user);
      } catch (error) {
        fail(error.response ? error.response.data : error.message);
      }
    },

    /**
     * Chama a rota de criação de um usuário, quando criado seta o token
     * e retorna o evento done
     */
     async register({ commit }, { data, done, fail }) {
      try {
        const profission = {
          owner: "Sócio e/ou Proprietário",
          marketing: "Gerente de Marketing",
          ecommerce: "Gerente de E-commerce",
          analytics: "Analista",
          student: "Estudante",
          other: "Outro",
        }[data.profission];

        const script = document.createElement("script");
        script.src = `https://increazy38535.activehosted.com/proc.php?u=5&f=5&s=&c=0&m=0&act=sub&v=2&fullname=${data.name}&email=${data.email}&customer_account=${data.company}&field[6]=${profission}&jsonp=true`;
        script.type = "text/javascript";
        script.charset = "utf-8";
        document.head.appendChild(script);

        const response = await window.$request().post("/auth/register", data);
        const { user, token } = response.data;
        commit("SET_USER", user);
        commit("SET_TOKEN", token);
        done(user);
      } catch (error) {
        fail(error.response ? error.response.data : error.message);
      }
    },

    async updateUser({ commit }, { data, done, fail }) {
      try {
        const response = await window.$request().put("/auth", data);
        const { user, token } = response.data;
        commit("SET_USER", user);
        commit("SET_TOKEN", token);
        done(user);
      } catch (error) {
        fail(error.response ? error.response.data : error.message);
      }
    },

    /**
     * Request responsável por enviar o email de recuperação de senha
     * para o usuário, através do email digitado.
     */
     async sendEmailRecovery({ commit }, { data, done, fail }) {
      try {
        const response = await window.$request().post("/auth/recovery", data);
        done(response.data);
        console.log(commit);
      } catch (error) {
        fail(error.response ? error.response.data : error.message);
      }
    },

    async recoveryPassword({ commit }, { data, done, fail }) {
      try {
        const token = window.$route.params.token;

        const response = await window
          .$request()
          .put(`/auth/recovery/${token}`, data);
        done(response.data);
        console.log(commit);
      } catch (error) {
        fail(error.response ? error.response.data : error.message);
      }
    },

    logout({ commit }) {
      commit("SET_USER", {});
      commit("SET_TOKEN", '');
    },
  },
  modules: {
  }
})
