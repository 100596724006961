export default (router) => {
  router.beforeEach((to, from, next) => {
    
    window.$route = to;

    if (to.matched.some((record) => record.meta.requireLogin)) {
      if (
        !localStorage.getItem("token") ||
        localStorage.getItem("token") === "null"
      ) {
        return next({ name: "auth-login", query: { ...to.query } });
      }
    }

    next();
  });
};
