import validator from "./validator";
import store from "./store";
import routeAuxiliars from "./route-auxiliars";

/**
 * Registra todos o utils no app Vue
 */
export const useUtils = (app) => {
  validator(app);
  store(app);
  routeAuxiliars(app);
};
