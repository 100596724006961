import { mapActions, mapGetters } from "vuex";

export default (app) => {
  app.mixin({
    computed: {
      ...mapGetters({
        $user: "getUser",
      }),
    },
    methods: {
      ...mapActions({
        $logout: "logout",
      }),
    },
  });
};
