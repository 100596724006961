
import { defineAsyncComponent } from 'vue'

/**
 * Objeto de todos componentes globais, de forma dinâmica
 * para serem usados na aplicação
 */
const components = {
  "form-input": defineAsyncComponent(() => import('@/components/form/Input.vue')),
  "form-request": defineAsyncComponent(() => import('@/components/form/Request.vue')),
  "grid-auth": defineAsyncComponent(() => import('@/components/grid-auth')),
  "grid-panel": defineAsyncComponent(() => import('@/components/grid-panel')),
  "toast": defineAsyncComponent(() => import('@/components/toast')),
  "loading": defineAsyncComponent(() => import('@/components/general/Loading.vue'))
};
 
 
/**
* Import dinâmico de todos os componentes definidos
*/
export const useComponents = (app) => {
	Object.keys(components).forEach((componentName) => {
		app.component(componentName, components[componentName]);
	});
};
 