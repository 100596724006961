import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import i18n  from '@/i18n';
import { useComponents } from "@/core/components";
import { useUtils } from "@/core/utils";
import mitt from 'mitt';
import store from './store';
import request from "@/core/request";
import middlewares from "@/core/middlewares";

const app = createApp(App)

const emitter = mitt();
app.config.globalProperties.emitter = emitter;

window.$request = request;

middlewares(router, store);
useComponents(app);
useUtils(app);

app.use(store);
app.use(router);
app.use(i18n);
app.mount('#app');
