import axios from "axios";

/**
 * Cria uma instância do axios com as configurações padrões para
 * a API do backend, esse método é armazenado na variável
 * window.$request para ser acessível no Vuex
 */
export default () => {
  let baseURL = process.env.VUE_APP_BACKEND_API;

  const { appID } = (window.$route || {}).params || {};
  const customHeaders = appID ? { App: appID } : {};

  let headers = {
    Language: localStorage.getItem("language"),
    Token: localStorage.getItem("token"),
    ...customHeaders,
  };

  return axios.create({
    baseURL: baseURL,
    timeout: 60000,
    headers: headers,
  });
};
